import { useContext } from "react"
import { FontContext } from "../../providers/fontProvider"
import { THEMES } from "../../data/themes.mjs";
import { defaultTheme, discountRate } from "../../config.mjs";

export function TitleStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)

    return {
        container: {
            ...fonts.h2,
            fontWeight: "400",
            width: "100%",
            color: theme.pageHeader,
            textTransform: "uppercase",
            textAlign: "center",
            padding: "0 0 24px"
        }
    }
}

export function SwatchStyle() { 
    const theme = THEMES[defaultTheme]
    const fonts = useContext(FontContext)
    
    return {
        swatchContainer: {
            display: "flex",
            flexWrap: "wrap",
            gap: "48px",
            width: "100%",
            maxWidth: "1700px",
            justifyContent: "center"
        },
        container: {
            display: "flex",
            flexDirection: "column",
            padding: "24px 16px",
            backgroundColor: theme.componentBackground,
            width: window.innerWidth < 500 ? "96vw" : "320px",
            gap: "18px"
        },
        imageContainer: {
            overflow: "hidden",
            width: "100%",
            cursor: "zoom-in"
        },
        optionContainer: {
            display: "flex",
            gap: "4px",
            justifyContent: "center",
            flexWrap: "wrap",
            cursor: "pointer"
        },
        optionBorder: {
            border: "2px solid transparent",
            borderRadius: "12px",
            textDecoration: "none",
            transitionDuration: ".2s"
        },
        option: {
            width: "40px",
            height: "40px",
            borderRadius: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: `2px solid ${theme.componentBackground}`
        },
        number: {
            ...fonts.small,
            color: "#ffffff"
        },
        name: {
            ...fonts.h3,
            textDecoration: "none",
            color: theme.componentHeader
        },
        tagline: {
            ...fonts.standard,
            textDecoration: "none",
            color: theme.componentText
        },
        priceContainer: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
            textDecoration: "none"
        },
        price: {
            fontSize: "20px",
            color: discountRate ? theme.specialBackground : theme.componentHeader,
            fontWeight: "600",
            paddingRight: "16px"
        },
        priceOld: {
            fontSize: "12px",
            width: "100%",
            paddingLeft: "8px",
            textDecoration: "line-through",
            color: theme.componentText,
            fontWeight: "500"
        },
        reviews: {
            ...fonts.standard,
            color: theme.componentHeader
        },
        button: {
            ...fonts.h4,
            color: theme.pageHeader,
            border: `2px solid ${theme.pageHeader}`,
            width: "100%",
            padding: "11px 22px",
            textTransform: "uppercase",
            textDecoration: "none",
            backgroundColor: theme.pageBackground,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "3px",
            transitionDuration: ".3s"
        }
    }
}