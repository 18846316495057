import { useContext, useEffect, useState } from "react"
import { ReactComponent as DeleteIcon } from '../../../icons/delete.svg'
import { ReactComponent as EditIcon } from '../../../icons/edit.svg'
import { CartPageStyle, CartStyle } from "../style"
import { THEMES } from "../../../data/themes.mjs"
import { defaultTheme } from "../../../config.mjs"
import { CartContext } from "../../../providers/cartProvider"
import { Link, useNavigate } from "react-router-dom"

export function Bag() {
    const navigate = useNavigate()
    const cart = useContext(CartContext)
    const items = cart.items
    const cartPageStyle = CartPageStyle()
    const style = CartStyle()
    const theme = THEMES[defaultTheme]
    const [vehicle, setVehicle] = useState()
    const [deliveryDate, setDeliveryDate] = useState()

    useEffect(() => {
        let vehicleNames = []
        for(var i = 0; i < items.length; i++)
            vehicleNames.push(items[i].year + ' ' + items[i].make + ' ' + items[i].model)
        setVehicle(vehicleNames)

        const date = new Date(new Date().getTime()+(15*24*60*60*1000))
        setDeliveryDate(date.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"}))
    }, [items, setVehicle])

    function editClick(product, id) {
        navigate(`/products/${product.line}/${product.product}?a=${product.colorID}&e=${id}`)
    }
    
    return (
        <div style={style.container}>
            <h1 style={cartPageStyle.title}>Shopping Cart</h1>
            { vehicle && (!items || items.length === 0) && <div style={style.noItems}>
                There are no items in your Shopping Cart
            </div> }
            { vehicle && (!items || items.length === 0) && <Link to='/products' className="remove-discount-hover" style={style.gotoProd}>
                Go to Products Page
            </Link> }
            { vehicle && items.length > 0 && items.map((prod, id) => {
                return <div key={id} style={{...style.itemContainer, borderBottom: `1px solid ${theme.borderColor}`}}>
                    <div style={{position: "relative"}}>
                        <img style={style.image} src={prod.image} alt={vehicle[id]} title={vehicle[id]} loading="eager" width={window.innerWidth < 780 ? "114px" : "164px"} height={window.innerWidth < 780 ? "114px" : "164px"} />
                        { prod.smallImage && !prod.upgradedConsole && <img style={style.smallImage} src={prod.smallImage} alt={vehicle[id] + ' Color'} loading='eager' width={window.innerWidth < 780 ? "40px" : "60px"} height={window.innerWidth < 780 ? "40px" : "60px"} /> }
                    </div>
                    <div style={style.detailsContainer}>
                        <div style={style.name}>{prod.productName} {prod.productType}</div>
                        <div style={style.details}>{vehicle[id]}</div>
                        <div style={style.details}>Item#: {prod.item}</div>
                        { prod.colorName && <div style={style.details}>{prod.upgradedConsole ? '' : `Color: ${prod.colorName}`}</div> }
                        { prod.row &&<div style={style.details}>{prod.upgradedConsole ? '' : `Row: ${prod.row}`}</div> }
                        <div style={style.details}>Quantity: {prod.quantity}</div>
                    </div>
                    <div style={style.costContainer}>
                        <div style={{display: "flex", flexDirection: "column", gap: "12px", alignItems: "flex-end"}}>
                            <div style={style.cost}>${prod.price.toFixed(2)}</div>
                            { !prod.upgradedConsole && <EditIcon style={style.delete} id={id} className="page-hover" onClick={() => editClick(prod, id)} /> }
                        </div>
                        <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
                            <DeleteIcon style={style.delete} id={id} className="page-hover" onClick={() => cart.removeItem(id)} />
                        </div>
                    </div>
                </div>
            })}
            { items.length > 0 && <>
                <div style={cartPageStyle.shippingTitle}>Shipping Information</div>
                <div style={cartPageStyle.shippingText}>Your shipping is FREE! Item requires 10-13 business days for production.</div>
                <div style={cartPageStyle.shippingTextDos}>Arrives on or before {deliveryDate}</div>
            </>}
        </div>
    )
}